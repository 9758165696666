/**
 * @deprecated
 *
 * Скрипт перенесён сюда из шаблонов при выпиливании assets.
 * Является базовым скриптом, который встречается на большинстве страниц
 */

/* eslint-disable */

import 'imports-loader?this=>window!libs/jquerymobile/1.4.5/jquery.mobile.min.js';

$.mobile.ajaxEnabled = false; // перенесено из vir_mobile.html (отключает ajax переходы между страницами)
